import { UserService } from 'src/app/services/user.service';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private userService: UserService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Get the auth token from the service.
        const authToken = this.userService.getToken();

        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        if (authToken) {

            if (this.userService.isLoggedOut()) {

                console.log('signOut Interceptor');

                this.userService.singOut();
                console.log('Token expirado');
            }

            const authReq = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${authToken}`
                }
            });

            // send cloned request with header to the next handler.
            return next.handle(authReq);

        } else {

            return next.handle(req);

        }
    }
}