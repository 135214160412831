import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  error$: Subject<string> = new Subject(); 
  success$: Subject<string> = new Subject(); 

  constructor() { }

  showError(message: string){
    this.error$.next(message);
  }

  showSuccess(message: string){
    this.success$.next(message);
  }

  getError(): Observable<string> {
    return this.error$;
  }

  getSuccess(): Observable<string> {
    return this.success$;
  }
}
