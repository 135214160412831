import { UserGuard } from './guards/user.guard';
import { SharedModule } from './shared/shared.module';
import { PanelsComponent } from './muestras/panels/panels.component';
import { ButtonsComponent } from './muestras/buttons/buttons.component';
import { AlertsComponent } from './muestras/alerts/alerts.component';
import { MuestrasModule } from './muestras/muestras.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ServicesModule } from './services/services.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    SharedModule,
    ServicesModule,
    MuestrasModule,
    RouterModule.forRoot([
      {
        path: '',
        loadChildren: () => import('./pages/general/general.module').then(m => m.GeneralModule),
        canActivate: [UserGuard]
      },
      {
        path: 'eloqua',
        loadChildren: () => import('./pages/eloqua/eloqua-component.module').then(m => m.EloquaComponentModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/dashboard', 
      },
      { path: 'alerts', component: AlertsComponent },
      { path: 'buttons', component: ButtonsComponent },
      { path: 'panels', component: PanelsComponent },

    ]),
    BrowserAnimationsModule
  ],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }]
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
