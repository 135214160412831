import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadscriptService {

  constructor() { }

  public loadScript(urlScript: string, force = false) {
    console.log('preparing to load...')

    const exist = document.querySelector(`script[src='${urlScript}']`);

    if (exist && !force) {
      console.log('script is loader with anteriority');
      return;
    }
    else if (exist && force) {
      console.log('script forzado a recargar');
      exist.remove();
    }

    const node = document.createElement('script');
    node.src = urlScript;
    node.type = 'text/javascript';
    node.async = true;
    // tslint:disable-next-line: deprecation
    node.charset = 'utf-8';
    document.body.appendChild(node);

    console.log('script loaded', urlScript);
  }

  public removeScript(urlScript: string) {
    const exist = document.querySelector(`script[src='${urlScript}']`);

    console.log('script eliminado');

    if (exist) {
      exist.remove();
    }
  }

}
