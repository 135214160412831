import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class InfobipService {
  constructor(private httpClient: HttpClient) {}

  getNumbers(instance: string) {
    return this.httpClient.get<any>(`api/infobip/numbers/${instance}`);
  }

  getNumberWhatsapp(instance: string) {
    return this.httpClient.get<any>(`api/InfobipWhatsapp/numbers/${instance}`);
  }

  getTemplatesWhatsapp(
    instance: string,
    infobipAccountId: string,
    sendNumber: string
  ) {
    ///{appId}/{infobipAccountId}/{sendNumber}
    return this.httpClient.get<any>(
      `api/InfobipWhatsapp/templates/${instance}/${infobipAccountId}/${sendNumber}`
    );
  }

   getNumberWhatsappWIMT(instance: string) {
    return this.httpClient.get<any>(`api/InfobipWhatsappWIMT/numbers/${instance}`);
  }

  getTemplatesWhatsappWIMT(
    instance: string,
    infobipAccountId: string,
    sendNumber: string
  ) {
    ///{appId}/{infobipAccountId}/{sendNumber}
    return this.httpClient.get<any>(
      `api/InfobipWhatsappWIMT/templates/${instance}/${infobipAccountId}/${sendNumber}`
    );
  }

}
