import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {

  @Input()
  title: string = "Sin Asignar";

  @Input()
  description: string = "Sin Asignar";

  @Input()
  icon: string;

  constructor() { }

  ngOnInit() {
  }

}
