import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  collapse: boolean = true;

  @Input()
  fullscreen: boolean = false;
 
  @Input()
  close: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
