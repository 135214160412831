import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-path',
  templateUrl: './header-path.component.html',
  styleUrls: ['./header-path.component.scss']
})
export class HeaderPathComponent implements OnInit, OnDestroy {

  path: any[] = [];
  date: Date;

  constructor(private location:Location) {

    const url = this.location.path();
    
    url.split('/').forEach(item => {
      if (item != "") {
        this.path.push(item);
      }
    });

    this.date = new Date();
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {
  }

}
