import { DateTime } from 'luxon';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';
import { Router } from '@angular/router';

import { User } from './../models/user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user$: BehaviorSubject<User> = new BehaviorSubject(null);
  token: string;
  expireIn: any;
  redirectURL: string;

  constructor(private httpClient: HttpClient, private _router: Router) {

    const user = localStorage.getItem("user");
    this.token = localStorage.getItem("token");
    this.expireIn = localStorage.getItem("expireIn");

    if (user) {
      const _user: User = JSON.parse(user) as User;

      this.user$.next(_user);
    }

  }

  signIn(email: string, password: string) {

    return this.httpClient.post(`api/user/signIn`, { email, password })
      .pipe(
        tap((token: any) => {

          localStorage.setItem('token', token.access_token);
          localStorage.setItem('expireIn', token.expireIn);

          this.token = token.access_token;
          this.expireIn = token.expireIn;

          this.getUserData();
          this.redirect();
        })
      );

  }

  public isAdmin(): boolean {

    if(this.user$.value){
      return this.user$.value.roles.includes("Administrator");
    }

    return false;
  }

  public getUserData() {

    this.httpClient.get<User>(`api/user`)
      .subscribe(user => {

        localStorage.setItem('user', JSON.stringify(user));
        this.user$.next(user);
      });

  }

  singOut() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('expireIn');
   
    this.user$.next(null);
    this.token = null;

    if (this.redirectURL) {
      this._router.navigate(['/account', 'signIn'], { queryParams: { redirect: this.redirectURL } });
    } else {

      this._router.navigate(['/account', 'signIn']);

    }
  }

  register(user: any) {
    return this.httpClient.post(`api/user/register`, user);
  }

  update(user: User){
    return this.httpClient.put(`api/user/update/${user.userId}`, user);
  }

  getUser() {
    return this.user$.asObservable();
  }

  getUsers() {
    return this.httpClient.get<User[]>(`api/user/list`);
  }

  getRoles() {
    return this.httpClient.get<string[]>(`api/user/roles`);
  }

  getToken() {
    return this.token;
  }

  getExpiration() {
    return this.expireIn;
  }

  public isLoggedIn() {
    const date = DateTime.fromISO(this.getExpiration());
    const diff = date.diffNow().as("minutes");

    return diff > 0;
  }

  isLoggedOut(url?: string) {
    const logOut = !this.isLoggedIn();

    if (logOut) { //SI ESTA DESLOGEADO
      if (url) {
        this.setRedirectUrl(url);
      }

      this.singOut();
    }

    return logOut;
  }

  setRedirectUrl(url: string) {
    this.redirectURL = url.replace('/', '');
  }

  private redirect() {
    //console.log('Redirect', this.redirectURL);

    if (this.redirectURL) {
      this._router.navigateByUrl('/' + this.redirectURL);
    }
    else {
      this._router.navigateByUrl('/');

    }

    this.redirectURL = '';
  }


  sendEmailForgot(email: string) {

    return this.httpClient.post("api/user/forgot", { emailAddress: email });

  }

  sendEmailRecover(email: string, token: string, elq: string, password: string, verifyPassword: string) {

    return this.httpClient.post("api/user/recover", { emailAddress: email, token, elq, password, verifyPassword });

  }

  sendEmailActivate(params : HttpParams){
    
    return this.httpClient.get("api/user/activate", { params });

  }

  updatePassword(form: any){
    return this.httpClient.post(`api/user/changepassword`, form);
  }

  acceptCookie() {
    return this.httpClient.get(`api/user/acceptcookie`)
    .pipe(
      tap(_ => {
        let user = this.user$.value;
        user.acceptCookie = true;

        localStorage.setItem('user', JSON.stringify(user));
        this.user$.next(user);
      })
    );
  }


}
