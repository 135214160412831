import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleComponent } from './title/title.component';
import { PanelComponent } from './panel/panel.component';
import { HeaderPathComponent } from './header-path/header-path.component';



@NgModule({
  declarations: [TitleComponent, PanelComponent, HeaderPathComponent],
  imports: [
    CommonModule
  ],
  exports: [
    TitleComponent,
    PanelComponent,
    HeaderPathComponent
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class SharedModule { }
