import { SharedModule } from './../shared/shared.module';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts/alerts.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { PanelsComponent } from './panels/panels.component';



@NgModule({
  declarations: [AlertsComponent, ButtonsComponent, PanelsComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class MuestrasModule { }
