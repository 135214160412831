import { UserService } from 'src/app/services/user.service';
import { LoadscriptService } from './loadscript.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { InfobipService } from './infobip.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    UserService,
    LoadscriptService, 
    NotificationService,
    InfobipService
  ]
})
export class ServicesModule { }
